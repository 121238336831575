<template>
  <div>
    <div @click="isOpen = !isOpen">
      <span v-if="isOpen">
        {{ status }}
      </span>
      <span v-else> {{ type }} </span>

      <v-icon>{{ !isOpen ? "mdi-chevron-down" : "mdi-chevron-up" }}</v-icon>
    </div>
    <v-slide-y-transition>
      <v-list v-show="isOpen" dense>
        <list-item-result
          v-for="hour of p_items"
          :key="hour.id"
          :value="getValueForHour(hour)"
          class="pl-0"
        >
          <v-list-item-content>
            <div
              class="d-flex"
              :class="{ 'text-subtitle-2': hour.id == current.id }"
            >
              <div class="pr-2 flex-grow-0" style="width: 120px">
                {{ getNameByKey(hour.weekday) }}
              </div>
              <div class="flex-grow-1">
                <template v-if="hour.type == 'range'">
                  {{ formatHour(hour.openAt) }} - {{ formatHour(hour.closeAt) }}
                </template>
                <template v-else-if="hour.type == 'closed'">
                  {{ $t("feature.close") }}
                </template>
                <template v-else-if="hour.type == 'all_day'">
                  {{ $t("feature.all-day") }}
                </template>
              </div>
            </div>
          </v-list-item-content>
        </list-item-result>
      </v-list>
    </v-slide-y-transition>
  </div>
</template>

<script>
import ListItemResult from "./Result/ListItemResult.vue";
export default {
  components: { ListItemResult },
  props: {
    items: { type: Array, default: () => [] },
  },
  data: (vm) => ({
    isOpen: false,
    names: {
      2: vm.$t("feature.week.2"),
      3: vm.$t("feature.week.3"),
      4: vm.$t("feature.week.4"),
      5: vm.$t("feature.week.5"),
      6: vm.$t("feature.week.6"),
      7: vm.$t("feature.week.7"),
      CN: vm.$t("feature.week.CN"),
    },
    p_items: [],
    current: null,
  }),
  created() {},
  watch: {
    items: {
      handler(value) {
        this.p_items = JSON.parse(JSON.stringify(value)).sort(
          (a, b) => a.weekday - b.weekday
        );
        let weekday = new Date().getDay();
        if (weekday == 0) weekday = "CN";
        else {
          weekday++;
        }
        this.current = this.p_items.find((x) => x.weekday == weekday);
      },
      immediate: true,
    },
  },
  computed: {
    status() {
      if (this.current.type == "closed") {
        return this.$t("feature.status.close");
      }
      if (this.current.type == "all_day") {
        return this.$t("feature.status.currently-open");
      }
      if (this.current.type == "range") {
        let current = new Date();
        let openAt = new Date(
          `${current.getFullYear()}-${
            current.getMonth() + 1
          }-${current.getDate()} ${this.current.openAt}`
        ).getTime();
        let closeAt = new Date(
          `${current.getFullYear()}-${
            current.getMonth() + 1
          }-${current.getDate()} ${this.current.closeAt}`
        ).getTime();
        current = current.getTime();
        if (current >= openAt && current <= closeAt)
          return this.$t("feature.status.currently-open");
      }
      return this.$t("feature.status.close");
    },
    type() {
      if (this.current.type == "closed") {
        return this.$t("feature.status.close");
      }
      if (this.current.type == "all_day") {
        return this.$t("feature.status.all-day");
      }
      if (this.current.type == "range") {
        let current = new Date();
        let openAt = new Date(
          `${current.getFullYear()}-${
            current.getMonth() + 1
          }-${current.getDate()} ${this.current.openAt}`
        ).getTime();
        let closeAt = new Date(
          `${current.getFullYear()}-${
            current.getMonth() + 1
          }-${current.getDate()} ${this.current.closeAt}`
        ).getTime();
        current = current.getTime();
        if (current >= openAt && current <= closeAt)
          return (
            this.$t("feature.status.open") +
            `: ${this.formatHour(this.current.openAt)} - ${this.formatHour(
              this.current.closeAt
            )}`
          );
      }
      return this.$t("feature.status.close");
    },
    c_items() {
      return this.p_items;
    },
  },
  methods: {
    formatHour(hour) {
      if (!hour) {
        return "";
      }
      if (hour.length > 5) {
        let index = hour.lastIndexOf(":");
        hour = hour.slice(0, index);
      }
      return hour;
    },
    getNameByKey(key) {
      return this.names[key] || key;
    },
    getTimeByHour(hour) {
      if (hour.type == "closed") {
        return this.$t("feature.status.close");
      }
      if (hour.type == "all_day") {
        return this.$t("feature.status.all-day");
      }
      if (hour.type == "range") {
        return `${this.formatHour(hour.openAt)} - ${this.formatHour(
          hour.closeAt
        )}`;
      }
      return this.$t("feature.status.close");
    },
    getValueForHour(hour) {
      return `${this.getNameByKey(hour.weekday)}, ${this.getTimeByHour(hour)}`;
    },
  },
};
</script>

<style scoped></style>
