<template>
  <v-hover v-slot="{ hover }">
    <v-list-item
      link
      class="position-relative text-body-2"
      v-bind="bindObject"
      v-on="eventObject"
    >
      <slot />
      <div v-show="hover" class="list-item-btn-container">
        <v-btn
          link
          icon
          :href="value"
          v-if="type == 'website'"
          target="_blank"
          title="Bấm để mở trang web"
        >
          <v-icon small>mdi-launch</v-icon>
        </v-btn>
        <v-btn
          link
          icon
          :href="`mailto:${value}`"
          v-if="type == 'email'"
          title="Bấm để mail đến địa chỉ này"
        >
          <v-icon small>mdi-email-send-outline</v-icon>
        </v-btn>
        <v-btn
          link
          icon
          :href="`tel:${value}`"
          v-if="type == 'phone'"
          title="Bấm để gọi số điện thoại này"
        >
          <v-icon small>mdi-phone-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="onCopyToClipboard"
          v-if="value"
          title="Bấm để sao chép vào clipboard"
        >
          <v-icon small>mdi-content-copy</v-icon>
        </v-btn>
      </div>
    </v-list-item>
  </v-hover>
</template>

<script>
export default {
  props: { value: {}, type: String },
  computed: {
    isCanClickToCopy() {
      if (!this.type) return true;
      return ["phone", "email"].includes(this.type);
    },
    bindObject() {
      if (this.type == "website") {
        return {
          title: "Bấm để mở trang",
        };
      }
      return {
        title: this.isCanClickToCopy ? "Bấm để sao chép vào clipboard" : "",
      };
    },
    eventObject() {
      if (this.type == "website") {
        return {
          click: this.onOpenWeb,
        };
      }
      return {
        click: this.isCanClickToCopy ? this.onCopyToClipboard : () => {},
      };
    },
  },
  methods: {
    onOpenWeb() {
      window.open(this.value, "_blank");
    },
    onCopyToClipboard() {
      const el = document.createElement("textarea");
      el.value = this.value;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$notify({
        title: "Sao chép vào clipboard",
      });
    },
  },
};
</script>

<style>
.list-item-btn-container {
  position: absolute;
  top: 2px;
  right: 5px;
  padding-left: 24px;
}
</style>
