<template>
  <v-card flat tile v-if="simple" class="pa-2 full-height">
    <div class="pt-2 full-width text-subtitle-2" :title="place.name">
      <div class="full-weight">
        {{ place.name }}
      </div>
    </div>
    <div class="text-caption">
      <div v-if="place.place">{{ place.place.name }}</div>
      <div v-if="place.address" class="text-truncate" :title="place.address">
        {{ place.address }}
      </div>
      <div>
        <StatusTypeText :value="!place.isClosed" />
      </div>
    </div>
  </v-card>
  <v-card flat tile v-else>
    <div class="d-flex">
      <div class="flex-grow-1">
        <v-card-title class="my-0 text-h6" style="word-break: break-word">{{
          place.name
        }}</v-card-title>
        <v-card-subtitle class="text-subtitle-2">
          <div class="ml-n1 py-2">
            <Rating :value="place.rating"></Rating>
          </div>

          <template v-if="place.place && place.place.name">
            {{ place.place.name }}</template
          >
          <template v-else-if="place.placeType">
            {{ place.placeType.name }}</template
          >
        </v-card-subtitle>
      </div>
      <div class="flex-grow-0" v-if="place.guideLink">
        <guide-link-button
          :link="place.guideLink"
          class="text-decoration-none d-block pt-3 pr-2"
        >
          <v-btn small fab text color="primary" title="Đường đi">
            <v-icon> mdi-directions </v-icon>
          </v-btn>
        </guide-link-button>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="pt-3">
      <v-list dense>
        <list-item-result :value="place.address" v-if="place.address">
          <v-list-item-icon class="mr-5">
            <v-icon color="primary">mdi-map-marker-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{ place.address }}
          </v-list-item-content>
        </list-item-result>
        <v-list-item v-if="place.workTimes" link class="text-body-2">
          <v-list-item-icon class="mr-4">
            <v-icon color="primary">mdi-alarm</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <OpenHour :items="place.workTimes" />
          </v-list-item-content>
        </v-list-item>
        <template v-if="contacts && contacts.length > 0">
          <list-item-result
            v-for="contact of contacts"
            :key="contact.id"
            :value="contact.value"
            :type="contact.type"
          >
            <v-list-item-icon class="mr-4">
              <v-icon color="primary">{{ getIconByType(contact.type) }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <a
                :href="contact.value"
                v-if="contact.type == 'website'"
                target="_blank"
                class="link-click"
              >
                {{ contact.value }}
              </a>
              <template v-else>
                {{ contact.value }}
              </template>
            </v-list-item-content>
          </list-item-result>
        </template>
      </v-list>
      <template v-if="!hiddenImages && images && images.length > 0">
        <v-divider></v-divider>
        <FileViewContainer :items="images">
          <template #default="{ viewFile }">
            <v-slide-group active-class="success" show-arrows>
              <v-slide-item v-for="item in images" :key="item.id">
                <v-card
                  class="ma-4 overflow-hidden"
                  height="148px"
                  width="112px"
                  flat
                  outlined
                  @click="viewFile(item, 'image')"
                >
                  <v-img :src="item.link" height="100%" width="100%"></v-img>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </template>
        </FileViewContainer>
      </template>
    </div>
  </v-card>
</template>

<script>
import StatusTypeText from "@/views/StatusTypeText.vue";
import Rating from "@components/rating.vue";
import OpenHour from "../OpenHour.vue";
import ListItemResult from "./ListItemResult.vue";
import { API_PLACE } from "@/service/api";
import { Place } from "@/service/model/Place";
export default {
  components: {
    OpenHour,
    StatusTypeText,
    ListItemResult,
    Rating,
    FileViewContainer: () => import("@components/File/FileViewContainer.vue"),
  },
  props: {
    value: { type: Object, default: () => ({}) },
    loading: Boolean,
    simple: Boolean,
    hiddenImages: Boolean,
  },
  data: () => ({
    place: {},
    icons: { phone: "mdi-phone", email: "mdi-email", website: "mdi-web" },
  }),
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.place = value;
          this.onSetLoading(true);
          API_PLACE({ id: value.id })
            .then((res) => {
              this.place = new Place(Object.assign({}, this.place, res));
            })
            .finally(() => {
              this.onSetLoading(false);
            });
        }
      },
      immediate: true,
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    contacts() {
      return (this.place.contacts || []).filter((x) => !!x.value);
    },
    images() {
      return (this.place.images || []).map((x) => ({ ...x, path: x.link }));
    },
  },
  methods: {
    getIconByType(type) {
      return this.icons[type];
    },
    onSetLoading(loading) {
      this.$emit("update:loading", loading);
    },
  },
};
</script>

<style scoped></style>
