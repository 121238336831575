<template>
  <div v-if="simple" class="d-flex align-center ml-1">
    {{ value }}
    <v-rating
      hover
      class="d-inline-block"
      length="1"
      size="16"
      color="#F7CD46"
      :value="1"
      background-color="#DADCE0"
      empty-icon="mdi-star"
      readonly
      dense
      half-increments
    ></v-rating>
  </div>
  <v-rating
    v-else
    hover
    length="5"
    size="16"
    color="#F7CD46"
    :value="value"
    background-color="#DADCE0"
    empty-icon="mdi-star"
    readonly
    dense
    half-increments
  ></v-rating>
</template>

<script>
export default {
  props: {
    value: {},
    simple: Boolean,
  },
};
</script>

<style></style>
